import store from '@/plugins/store';

export const downloadCanvasAsPng = function (title, canvas) {
  downloadUrl(title + '.png', canvas.toDataURL('image/png'));
};

export const downloadDataAsCsv = function (title, headers, data) {
  if (data.length === 0) {
    store.commit('flash/flash', {
      text: 'messages.utils.download.downloadDataAsCsv.noDataToDownload',
      type: 'error',
    });
  } else {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += [
      (headers.join(';')),
      ...data.map(item => (Object.values(item).join(';'))),
    ]
      .join('\n')
      .replace(/(^\[)|(\]$)/gm, '')
    ;

    downloadUrl(title + '.csv', encodeURI(csvContent));
  }
};

export const downloadUrl = function (title, url) {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', title);
  link.click();
};
