<template>
  <div>
    <div>
      <o-period-selector
        ref="periodSelector"
        :mode="mode"
        :date="current"
        @mode-change="onModeChange"
        @date-change="onDateChange"
      >
        <span slot="actions">
          <v-btn-toggle
            v-model="dataType"
            color="secondary"
            dense
            group
          >
            <v-btn value="TYPE_OCCUPANCY">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-multiple
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.history.occupancy') }}
            </v-btn>
            <v-btn value="TYPE_LANE_COUNTING">
              <v-icon
                size="20"
                class="mr-1"
              >
                mdi-car-3-plus
              </v-icon>
              {{ $t('messages.views.admin.components.dashboard.crossroadModal.window.history.counting') }}
            </v-btn>
          </v-btn-toggle>
        </span>
      </o-period-selector>
    </div>

    <v-row>
      <v-col
        v-if="dataType === 'TYPE_LANE_COUNTING'"
        cols="6"
      >
        <o-card-stat
          class="fill-height history-card elevation-0 rounded-lg"
          outlined
          :title="$t('messages.views.admin.components.dashboard.crossroadModal.window.history.countingEvolution')"
          :loading="loadingHistory"
          :has-data="hasData(history.counting)"
          @download-chart-as-csv="() => downloadChartAsCsv('countingEvolution')"
          @download-chart-as-png="() => downloadChartAsPng('countingEvolution')"
        >
          <template
            v-slot:default="{ expanded }"
            class="fill-height fill-width"
          >
            <o-chart-counting-evolution
              ref="countingEvolutionChart"
              class="fill-height fill-width"
              :labels="chartLabels"
              :data="history.counting"
              :counters="computedCounters"
              :height="expanded ? 1000 : chartHeight"
              :selected-counter-id="selectedCounterId"
            />
          </template>
        </o-card-stat>
      </v-col>
      <v-col
        v-if="dataType === 'TYPE_OCCUPANCY'"
        cols="6"
      >
        <o-card-stat
          class="fill-height history-card elevation-0 rounded-lg"
          outlined
          :title="$t('messages.views.admin.components.dashboard.crossroadModal.window.history.occupancyEvolution')"
          :loading="loadingHistory"
          :has-data="hasData(history.occupancy)"
          @download-chart-as-csv="() => downloadChartAsCsv('occupancyEvolution')"
          @download-chart-as-png="() => downloadChartAsPng('occupancyEvolution')"
        >
          <template
            v-slot:default="{ expanded }"
            class="fill-height fill-width"
          >
            <o-chart-occupancy-evolution
              ref="occupancyEvolutionChart"
              class="fill-height fill-width"
              :labels="chartLabels"
              :data="history.occupancy"
              :counters="computedCounters"
              :height="expanded ? 1000 : chartHeight"
              :selected-counter-id="selectedCounterId"
            />
          </template>
        </o-card-stat>
      </v-col>
      <v-col
        cols="6"
      >
        <o-card-counters
          v-model="selectedCounterId"
          class="fill-height history-card elevation-0 rounded-lg"
          outlined
          :counters="loadingCrossroad ? [] : computedCounters"
          :sensors="loadingCrossroad ? [] : computedSensors"
          :loading="loadingCrossroad"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { downloadCanvasAsPng, downloadDataAsCsv } from '@/utils/download';
  import { getChartDefaultColors } from '@utils/color';
  const moment = require('moment-timezone');

  export default {
    props: {
      value: {
        type: [Number, String],
        required: true,
      },
    },

    apollo: {
      crossroad: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/window/history/getCrossroad.gql'),
        variables: function () {
          return {
            id: this.value,
          };
        },
        client: 'stations',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loadingCrossroad = res.loading;
        },
        update (data) {
          data.getCrossroad.counters.sort((a, b) => a.number > b.number);

          const colors = getChartDefaultColors(data.getCrossroad.counters.length);
          for (const index in data.getCrossroad.counters) {
            data.getCrossroad.counters[index].color = colors[index];
          }

          return data.getCrossroad;
        },
      },
      data: {
        query: require('@gql/views/admin/components/dashboard/crossroad-modal/window/history/history.gql'),
        skip: function () {
          return !this.crossroad;
        },
        variables: function () {
          return {
            timezone: moment.tz.guess(),
            from: this.from.clone().utc().format('Y-MM-DD HH:mm:ss'),
            to: this.to.clone().utc().format('Y-MM-DD HH:mm:ss'),
            step: this.step,
            occupancyCounterIds: this.getCounterIds('TYPE_OCCUPANCY'),
            countingCounterIds: this.getCounterIds('TYPE_LANE_COUNTING'),
            withOccupancy: this.getCounterIds('TYPE_OCCUPANCY').length > 0,
            withCounting: this.getCounterIds('TYPE_LANE_COUNTING').length > 0,
          };
        },
        client: 'stats',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        result (res, key) {
          this.loadingHistory = res.loading;
        },
        update: function (data) {
          this.history.counting = data.counting
            ? this.historyToChartData(data.counting)
            : []
          ;
          this.history.occupancy = data.occupancy
            ? this.historyToChartData(this.convertHistoryToPercentage(data.occupancy, 60))
            : []
          ;
        },
      },
    },

    data: function () {
      return {
        selectedCounterId: null,
        dataType: 'TYPE_OCCUPANCY',
        chartHeight: 280,
        current: moment(new Date()).set({
          minute: 0,
          second: 0,
          millisecond: 0,
        }).toDate(),
        mode: 'day',
        loadingCrossroad: true,
        loadingHistory: true,
        history: {
          counting: null,
          occupancy: null,
        },
      };
    },

    computed: {
      computedCounters () {
        if (!this.crossroad) {
          return [];
        }

        return this.crossroad.counters
          .filter((counter) => counter.type === this.dataType)
        ;
      },

      computedSensors () {
        const ids = [];

        for (const counter of this.computedCounters) {
          for (const sensorId of counter.sensors) {
            if (!ids.includes(sensorId)) {
              ids.push(sensorId);
            }
          }
        }

        const sensors = [];
        for (const sensorId of ids) {
          const sensor = this.crossroad.station.sensors.find((sensor) => sensor.id === sensorId);
          sensors.push(sensor);
        }

        return sensors;
      },

      from () {
        switch (this.mode) {
          case 'hour':
            return moment(this.current);
          case 'day':
            return moment(this.current).set({
              hour: 0,
            });
          case 'week':
            return moment(this.current).set({
              isoWeekday: 1,
              hour: 0,
            });
          case 'month':
            return moment(this.current).set({
              date: 1,
              hour: 0,
            });
          default:
            throw new Error('Invalid mode');
        }
      },

      to () {
        const to = this.from.clone();
        switch (this.mode) {
          case 'hour':
            return to.add(1, 'hours');
          case 'day':
            return to.add(1, 'days');
          case 'week':
            return to.add(7, 'days');
          case 'month':
            return to.add(1, 'months').date(0);
          default:
            throw new Error('Invalid mode');
        }
      },

      step () {
        switch (this.mode) {
          case 'hour':
            return 1;
          case 'day':
            return 30;
          case 'week':
            return 60 * 24;
          case 'month':
            return 60 * 24;
          default:
            throw new Error('Invalid mode');
        }
      },

      timestamps () {
        const timestamps = [];
        const startingTimeStamp = parseInt(this.from.format('X'));
        const endingTimeStamp = parseInt(this.to.format('X'));
        let currentTimestamp = startingTimeStamp;

        while (currentTimestamp < endingTimeStamp) {
          timestamps.push(currentTimestamp);
          currentTimestamp += this.step * 60;
        }

        return timestamps;
      },

      chartLabels () {
        return this.timestamps.map((timestamp) => {
          const date = moment.unix(timestamp);
          switch (this.mode) {
            case 'hour':
            case 'day':
              return date.format('LT');
            case 'week':
              return [
                date.format('dddd'),
                date.format('L'),
              ];
            default:
              return date.format('L');
          }
        });
      },
    },

    watch: {
      computedCounters (newValue) {
        this.selectedCounterId = null;
      },
    },

    methods: {
      downloadChartAsCsv (type) {
        const headers = [];
        switch (type) {
          case 'occupancyEvolution':
          case 'countingEvolution':
            headers.push(this.$t('messages.views.admin.components.dashboard.crossroadModal.window.history.csv.headers.timestamp'));

            for (const counter of this.computedCounters) {
              headers.push(this.$t('messages.views.admin.components.dashboard.crossroadModal.window.history.csv.headers.counter', {
                number: counter.number,
              }));
            }
            break;
        }

        const data = [];

        switch (type) {
          case 'occupancyEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              for (const counter of this.computedCounters) {
                values.push(this.history.occupancy[counter.id] !== undefined
                  ? this.history.occupancy[counter.id][i]
                  : null
                );
              }

              data.push([
                this.chartLabels[i],
                ...values,
              ]);
            }
            break;
          case 'countingEvolution':
            for (const i in this.chartLabels) {
              const values = [];
              for (const counter of this.computedCounters) {
                values.push(this.history.counting[counter.id] !== undefined
                  ? this.history.counting[counter.id][i]
                  : null
                );
              }

              data.push([
                this.chartLabels[i],
                ...values,
              ]);
            }
            break;
        }

        downloadDataAsCsv('counters_' + type, headers, data);
      },

      downloadChartAsPng (type) {
        const canvas = this.$refs[type + 'Chart'].$refs.canvas;
        downloadCanvasAsPng('section_' + type, canvas);
      },

      historyToChartData (history) {
        const data = {};

        for (const item of history) {
          const timestamp = parseInt(item.timestamp);
          const entityId = parseInt(item.entityId);
          const value = parseFloat(item.value);

          if (data[entityId] === undefined) {
            data[entityId] = Array(this.timestamps.length).fill(null);
          }

          const index = this.timestamps.indexOf(timestamp);
          data[entityId][index] = value;
        }

        return data;
      },

      convertHistoryToPercentage (history, max) {
        for (const item of history) {
          const value = parseFloat(item.value);
          item.value = Math.round(value / max * 100);
        }

        return history;
      },

      onModeChange (newMode) {
        this.mode = newMode;
      },

      onDateChange (newDate) {
        this.current = newDate;
      },

      hasData (values) {
        if (this.loadingHistory) {
          return true;
        }

        for (const counterId of Object.keys(values)) {
          for (const value of values[counterId]) {
            if (value !== null) {
              return true;
            }
          }
        }

        return false;
      },

      getCounterIds (type) {
        return this.crossroad.counters
          .filter((counter) => counter.type === type)
          .map((counter) => counter.id)
        ;
      },
    },
  };
</script>

<style scoped lang="sass">
  .history-card
    height: 358px !important
</style>
